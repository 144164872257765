<template>
  <div>
    <common-expansion-panel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        <span class="titulo-header">Datos del Conductor Asegurado</span>
      </template>
      <v-expansion-panel-content>
        <span class="text-body-1 px-1"
          >El conductor asegurado es la persona que conduce la mayor parte del
          tiempo el vehículo</span
        >
        <div v-if="contractor" class="mt-2">
          <v-alert type="info" color="#00a7e4" text>
            <span
              >Los datos se llenaran con la misma información del
              contratante.</span
            >
          </v-alert>

          <v-row>
            <v-col cols="12" md="6">
              <v-btn block rounded dark outlined color="#00a7e4">
                <span style="padding-right: 11%;">
                  Regresar
                </span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                class="common-botton"
                @click="
                  $emit('nextStep')
                  sendGtm()
                "
              >
                <span style="padding-right: 11%;">
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-form
          v-else
          ref="form"
          v-model="isFormValid"
          lazy-validation
          class="mt-5"
        >
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="first_name"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>Nombre(s) <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="lastname"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>Apellido Paterno <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="second_lastname"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>Apellido Materno <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="contactBirthDate"
                outlined
                type="date"
                :rules="[$rules.required]"
              >
                <template #label>
                  <span
                    >Fecha de Nacimiento <span class="red--text">*</span></span
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="contact_rfc"
                outlined
                :rules="[$rules.required, $rules.rfc]"
              >
                <template #label>
                  <span>RFC <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group v-model="contactGender" class="d-flex">
                <v-row no-gutters>
                  <v-col cols="4">
                    <span>Género</span>
                  </v-col>
                  <v-col cols="4">
                    <v-radio label="Femenino" value="F"></v-radio>
                  </v-col>
                  <v-col cols="4">
                    <v-radio label="Masculino" value="M"></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <span class="subtitulo-header">Domicilio del conductor</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="driverZipCode"
                outlined
                :rules="[$rules.required]"
                type="number"
              >
                <template #label>
                  <span>CP <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="driverSuburbCode"
                :items="colonias"
                item-text="suburb_name"
                item-value="suburb_code"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>Colonia <span class="red--text">*</span></span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="estado"
                outlined
                :rules="[$rules.required]"
                readonly
                :disabled="!!estado"
              >
                <template #label>
                  <span>Estado <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="driver_street"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>Calle <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="driver_ext"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>No. Exterior <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="driver_int"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>No. Interior <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <span class="subtitulo-header">Datos de contacto</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="contactPhone"
                outlined
                :rules="[$rules.required, $rules.min(10)]"
                :maxLength="10"
                type="number"
              >
                <template #label>
                  <span>Teléfono <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <common-email-autocomplete
                :emailEdit="email"
                @emailUpdate="(e) => (email = e)"
              ></common-email-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <common-email-autocomplete
                :emailEdit="emailConfirmation"
                customLabelText="Confirmar correo eléctronico"
                :mustHasValue="email"
                @emailUpdate="(e) => (emailConfirmation = e)"
              ></common-email-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-btn block rounded dark outlined color="#00a7e4">
                <span style="padding-right: 11%;">
                  Regresar
                </span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                class="common-botton"
                :disabled="!isFormValid"
                @click="nextStep()"
              >
                <span style="padding-right: 11%;">
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </common-expansion-panel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { getInsurerLocations } from "@/services/location.service";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";
import RfcFacil from "rfc-facil";

export default {
  components: {
    CommonExpansionPanel,
    CommonEmailAutocomplete,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPanel: 1,
      isFormValid: false,
      requiredChecks: {
        1: false,
        2: false,
        3: false,
        4: false,
      },

      colonias: [],
      estado: null,
      emailConfirmation: "",
    };
  },

  computed: {
    first_name: {
      get() {
        return this.$store.state.emission.driverData.first_name;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "first_name",
          value,
        });
      },
    },

    lastname: {
      get() {
        return this.$store.state.emission.driverData.lastname;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "lastname",
          value,
        });
      },
    },

    second_lastname: {
      get() {
        return this.$store.state.emission.driverData.second_lastname;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "second_lastname",
          value,
        });
      },
    },

    contactBirthDate: {
      get() {
        return this.$store.state.emission.driverData.contactBirthDate;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "contactBirthDate",
          value,
        });
      },
    },

    contact_rfc: {
      get() {
        return this.$store.state.emission.driverData.contact_rfc;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "contact_rfc",
          value,
        });
      },
    },

    contactGender: {
      get() {
        return this.$store.state.emission.driverData.contactGender;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "contactGender",
          value,
        });
      },
    },

    driverZipCode: {
      get() {
        return this.$store.state.emission.driverData.driverZipCode;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "driverZipCode",
          value,
        });
      },
    },

    driverTownshipCode: {
      get() {
        return this.$store.state.emission.driverData.driverTownshipCode;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "driverTownshipCode",
          value,
        });
      },
    },

    driverStateCode: {
      get() {
        return this.$store.state.emission.driverData.driverStateCode;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "driverStateCode",
          value,
        });
      },
    },

    driverSuburbCode: {
      get() {
        return this.$store.state.emission.driverData.driverSuburbCode;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "driverSuburbCode",
          value,
        });
      },
    },

    driver_street: {
      get() {
        return this.$store.state.emission.driverData.driver_street;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "driver_street",
          value,
        });
      },
    },

    driver_int: {
      get() {
        return this.$store.state.emission.driverData.driver_int;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "driver_int",
          value,
        });
      },
    },

    driver_ext: {
      get() {
        return this.$store.state.emission.driverData.driver_ext;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "driver_ext",
          value,
        });
      },
    },

    contactPhone: {
      get() {
        return this.$store.state.emission.driverData.contactPhone;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "contactPhone",
          value,
        });
      },
    },

    email: {
      get() {
        return this.$store.state.emission.driverData.email;
      },
      set(value) {
        this.$store.dispatch("updateDriverDataProperty", {
          key: "email",
          value,
        });
      },
    },

    contractor() {
      return this.$store.state.emission.emissionRequest.contractor;
    },

    insurerName() {
      return this.$store.state.emission.insurerData.insurer;
    },
  },

  watch: {
    driverZipCode(v) {
      if (v && v.length == 5) this.getColonias();
    },

    driverSuburbCode(v) {
      !!v && this.coloniaUpdated();
    },

    active(v) {
      if (v) this.currentPanel = 0;
      else this.currentPanel = 1;
    },

    first_name() {
      this.updateRfc();
    },

    lastname() {
      this.updateRfc();
    },

    second_lastname() {
      this.updateRfc();
    },

    contactBirthDate() {
      this.updateRfc();
    },
  },

  methods: {
    async getColonias() {
      const locations = await getInsurerLocations({
        insurer: this.insurerName,
        cp: this.driverZipCode,
      });
      this.colonias = locations;

      this.driverSuburbCode = this.colonias[0].suburb_code;
      this.coloniaUpdated();
    },

    coloniaUpdated() {
      const coloniaFound = this.colonias.find(
        (colonia) => colonia.suburb_code == this.driverSuburbCode
      );

      this.estado = coloniaFound.state_name;
      this.driverTownshipCode = coloniaFound.township_code;
      this.driverStateCode = coloniaFound.state_code;
    },
    sendGtm() {
      const noCotizacion = JSON.parse(sessionStorage.getItem('cotizacionSelected'));
      const emissionData = JSON.parse(sessionStorage.getItem('contractorData'))
      const cpData = JSON.parse(sessionStorage.getItem('carPrice'))
      window.dataLayer.push({
        event: "ITFY_auto_emitir_datos_asegurado",
        no_cotizacion: noCotizacion.cotizacion_general,
        sexo: this.gender == "M" ? "Masculino" : "Femenino",
        cp: this._data.colonias[0] ? this._data.colonias[0].zip_code : cpData?.personalData?.cp,
        correo: emissionData?.emission?.contractorData?.email,
        telefono: emissionData?.emission?.contractorData?.phone,
        userID: localStorage.getItem('agenteMail'),
        boton_contacto: "Listo",
      })
      window.dataLayer.push({
        event: "ITFY_auto_emitir_confirmar_compra_v",
        no_cotizacion: noCotizacion.cotizacion_general,
        userID: localStorage.getItem('agenteMail'),
        correo: emissionData?.emission?.contractorData?.email,
        telefono: emissionData?.emission?.contractorData?.phone,
      })
    },
    async nextStep() {
      await this.$refs.form.validate();
      const noCotizacion = JSON.parse(sessionStorage.getItem('cotizacionSelected'));
      const emissionData = JSON.parse(sessionStorage.getItem('contractorData'))
      window.dataLayer.push({
        event: "ITFY_auto_emitir_datos_asegurado",
        no_cotizacion: noCotizacion.cotizacion_general,
        sexo: this.gender == "M" ? "Masculino" : "Femenino",
        cp: this._data.colonias[0].zip_code,
        correo: this.email,
        telefono: this.contactPhone,
        userID: localStorage.getItem('agenteMail'),
        boton_contacto: "Listo",
      })
      window.dataLayer.push({
        event: "ITFY_auto_emitir_confirmar_compra_v",
        no_cotizacion: noCotizacion.cotizacion_general,
        userID: localStorage.getItem('agenteMail'),
        correo: emissionData?.emission?.contractorData?.email,
        telefono: emissionData?.emission?.contractorData?.phone,
      })
      if (!this.isFormValid) return;
      this.$emit("nextStep");
    },

    updateRfc() {
      if (
        this.first_name &&
        this.lastname &&
        this.second_lastname &&
        this.contactBirthDate
      )
        this.contact_rfc = RfcFacil.forNaturalPerson({
          name: this.first_name,
          firstLastName: this.lastname,
          secondLastName: this.second_lastname,
          day: this.contactBirthDate.split("-")[2],
          month: this.contactBirthDate.split("-")[1],
          year: this.contactBirthDate.split("-")[0],
        });
    },
  },
};
</script>
