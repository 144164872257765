<template>
  <div style="text-align: center; align-items: center;">
    <div style="text-align: -webkit-center; ">
      <v-img
        class="mx-5 responsive-img mb-2"
        contain
        :max-width="'100'"
        :src="greenCheck"
      ></v-img>
    </div>

    <span class="titulo-header" style="padding-top: 100px;">Pago exitoso</span>

    <div style="padding: 50px;">
      <span class="subtitulo-header-2 font-montserrat">
        Felicidades, hemos recibido tu pago y desde ahora disfrutas de las
        ventajas de pertenece a interprotecciones.
      </span>
      <br /><br />
      <span class="subtitulo-header-2 font-montserrat">
        Puedes consultar los detalles de tu emision
      </span>
      <v-row class="pt-5">
        <v-col cols="12">
          <v-btn class="common-botton" dark  @click="irPoliza"
            >AQUI</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn class="common-botton" dark  @click="getPolizaPDF"
            >DESCARGAR POLIZA</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import greenCheck from "@/assets/greenCheck.png";
import { mainAxios } from "@/mainAxios.js";

export default {
  props: {
    elevation: {
      type: [Number, String],
      default: 3,
    },
    emisionId:{
      type: [Number, String],
      default: null
    }

  },
  data() {
    return {
      greenCheck: greenCheck,
      agentes:[]
    };
  },
  methods:{
    irPoliza(){
      this.$router.push(`/detalles-emision/${this.emisionId}`);
      const dataCotizacion = JSON.parse(sessionStorage.getItem("cotizacionSelected"))
      window.dataLayer.push({
        event: "ITFY_auto_emitir_exitoso_v",
        no_cotizacion: dataCotizacion?.cotizacion_general,
        boton_contacto: "Aqui",
        userID: localStorage.getItem("agenteMail")
      })
    },
    getPolizaPDF(){
      this.infoModal()
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get('/v1/emision/'+this.emisionId+'/pdf/url', config).then((response) => {
        if(response.data.status){
          window.open(response.data.data.url, "_blank");
          const dataCotizacion = JSON.parse(sessionStorage.getItem("cotizacionSelected"))
          window.dataLayer.push({
            event: "ITFY_auto_emitir_exitoso_v",
            no_cotizacion: dataCotizacion?.cotizacion_general,
            boton_contacto: "Descargar poliza",
            userID: localStorage.getItem("agenteMail")
          })
        }
        else
          this.errorModal('No pudimos encontrar el PDF de tu póliza')
      });
    },
    errorModal(text){
      this.$toast.error(text, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    infoModal(){
      this.$toast.info('Construyendo PDF de emisión esperé un momento...', {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
  },
  mounted(){
    // const carData = JSON.parse(sessionStorage.getItem("carPrice"))
    const dataCotizacion = JSON.parse(sessionStorage.getItem("cotizacionSelected"))
    window.dataLayer.push({
      event: "ITFY_auto_emitir_exitoso_v",
      no_cotizacion: dataCotizacion?.cotizacion_general,
      modalidad_pago: dataCotizacion?.tipoPago,
      plan: dataCotizacion?.plan,
      aseguradora: dataCotizacion?.insurer,
      total_pago: dataCotizacion?.price,
      userID: localStorage.getItem("agenteMail")
    })
  }
};
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
  max-width: "100%";
}
.mi-clase-con-sombra {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1) !important;
}
</style>
