<template>
  <div>
    <common-expansion-panel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        <span class="titulo-header">Datos del Contratante</span>
      </template>
      <v-expansion-panel-content>
        <span class="text-body-1 px-1"
          >El contratante es la persona que paga la póliza</span
        >
        <v-form
          ref="formData"
          v-model="isFormValidContractorData"
          class="mt-5"
        >
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="firstName"
                outlined
                :rules="[$rules.required]"
                disabled
              >
                <template #label>
                  <span>Nombre(s) <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="lastname"
                outlined
                :rules="[$rules.required]"
                disabled
              >
                <template #label>
                  <span>Apellido Paterno <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="secondLastname"
                outlined
                :rules="[$rules.required]"
                disabled
              >
                <template #label>
                  <span>Apellido Materno <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="birthDate"
                outlined
                type="date"
                :rules="[$rules.required]"
                disabled
              >
                <template #label>
                  <span
                    >Fecha de Nacimiento <span class="red--text">*</span></span
                  >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="rfc"
                outlined
                :rules="[$rules.required, $rules.rfc]"
              >
                <template #label>
                  <span>RFC <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group v-model="gender" class="d-flex" disabled>
                <v-row no-gutters>
                  <v-col cols="4">
                    <span>Género</span>
                  </v-col>
                  <v-col cols="4">
                    <v-radio label="Femenino" value="F"></v-radio>
                  </v-col>
                  <v-col cols="4">
                    <v-radio label="Masculino" value="M"></v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <span class="subtitulo-header">Domicilio fiscal</span>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="cp"
                outlined
                :rules="[$rules.required]"
                type="number"
              >
                <template #label>
                  <span>CP <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="suburbCode"
                :items="colonias"
                item-text="suburb_name"
                item-value="suburb_code"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>Colonia <span class="red--text">*</span></span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="stateName"
                outlined
                :rules="[$rules.required]"
                readonly
                :disabled="!!stateName"
              >
                <template #label>
                  <span>Estado <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="contact_street"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>Calle <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="contractor_ext"
                outlined
                :rules="[$rules.required]"
              >
                <template #label>
                  <span>No. Exterior <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="contractor_int" outlined>
                <template #label>
                  <span>No. Interior <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <span class="subtitulo-header">Datos de contacto</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="phone"
                outlined
                :rules="[$rules.required]"
                disabled
              >
                <template #label>
                  <span>Teléfono <span class="red--text">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="email"
                outlined
                :rules="[$rules.required]"
                disabled
              >
                <template #label>
                  <span
                    >Correo eléctronico <span class="red--text">*</span></span
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <span class="subtitulo-header">Ayúdanos con estas preguntas</span>
            </v-col>
            <v-col cols="12" md="6">
              <div class="d-flex align-center">
                <span class="text-body-1 mr-3"
                  >¿Deseas facturar esta compra?</span
                >
                <v-switch v-model="invoice" inset color="#00a7e4"></v-switch>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="d-flex align-center">
                <span class="text-body-1 mr-3"
                  >¿El conductor asegurado es la misma persona que el
                  contratante?</span
                >
                <v-switch
                  v-model="contractor"
                  :disabled="!isFormValidContractorData"
                  inset
                  color="#00a7e4"
                ></v-switch>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="invoice">
            <v-col cols="12">
              <span class="subtitulo-header">Datos para facturación</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="cfdi"
                :items="cfdis"
                item-text="label"
                item-value="cfdi"
                label="Régimen fiscal"
                outlined
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="use_cfdi"
                :items="usesCfdi"
                item-text="label"
                item-value="use"
                label="Uso de CFDI"
                outlined
                disabled
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-btn block rounded dark outlined color="#00a7e4">
                <span style="padding-right: 11%;">
                  Regresar
                </span>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                block
                rounded
                dark
                class="common-botton"
                :disabled="!isFormValidContractorData"
                @click="nextStep()"
              >
                <span style="padding-right: 11%;">
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </common-expansion-panel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { getInsurerLocations } from "@/services/location.service";
import { getCfdis, getUsesCfdi } from "../../../../services/cfdi.service";

export default {
  components: {
    CommonExpansionPanel,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPanel: 1,
      isFormValidContractorData: false,
      requiredChecks: {
        1: false,
        2: false,
        3: false,
        4: false,
      },

      colonias: [],
      cfdis: [],
      usesCfdi: [],
    };
  },

  computed: {
    firstName: {
      get() {
        return this.$store.state.emission.contractorData.firstName;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "firstName",
          value,
        });
      },
    },

    lastname: {
      get() {
        return this.$store.state.emission.contractorData.lastname;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "lastname",
          value,
        });
      },
    },

    secondLastname: {
      get() {
        return this.$store.state.emission.contractorData.secondLastname;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "secondLastname",
          value,
        });
      },
    },

    birthDate: {
      get() {
        return this.$store.state.emission.contractorData.birthDate;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "birthDate",
          value,
        });
      },
    },

    rfc: {
      get() {
        return this.$store.state.emission.emissionRequest.contractor_rfc;
      },
      set(value) {
        this.$store.dispatch("updateEmissionRequestProperty", {
          key: "contractor_rfc",
          value,
        });
      },
    },

    gender: {
      get() {
        return this.$store.state.emission.contractorData.gender;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "gender",
          value,
        });
      },
    },

    contact_street: {
      get() {
        return this.$store.state.emission.emissionRequest.contact_street;
      },
      set(value) {
        this.$store.dispatch("updateEmissionRequestProperty", {
          key: "contact_street",
          value,
        });
      },
    },

    contractor_int: {
      get() {
        return this.$store.state.emission.emissionRequest.contractor_int;
      },
      set(value) {
        this.$store.dispatch("updateEmissionRequestProperty", {
          key: "contractor_int",
          value,
        });
      },
    },

    contractor_ext: {
      get() {
        return this.$store.state.emission.emissionRequest.contractor_ext;
      },
      set(value) {
        this.$store.dispatch("updateEmissionRequestProperty", {
          key: "contractor_ext",
          value,
        });
      },
    },

    email: {
      get() {
        return this.$store.state.emission.contractorData.email;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "email",
          value,
        });
      },
    },

    phone: {
      get() {
        return this.$store.state.emission.contractorData.phone;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "phone",
          value,
        });
      },
    },

    contractor: {
      get() {
        return this.$store.state.emission.emissionRequest.contractor;
      },
      set(value) {
        this.$store.dispatch("updateEmissionRequestProperty", {
          key: "contractor",
          value,
        });
        if (value) {
          const noCotizacion = JSON.parse(
            sessionStorage.getItem("cotizacionSelected")
          );
          const emissionData = JSON.parse(
            sessionStorage.getItem("contractorData")
          );
          window.dataLayer.push({
            event: "ITFY_auto_emitir_datos_contratante",
            no_cotizacion: noCotizacion.cotizacion_general,
            sexo: this.gender == "M" ? "Masculino" : "Femenino",
            cp: this.cp,
            correo: emissionData?.emission?.contractorData?.email,
            telefono: emissionData?.emission?.contractorData?.phone,
            userID: localStorage.getItem("agenteMail"),
            boton_contacto: "Listo",
          });
          window.dataLayer.push({
            event: "ITFY_auto_emitir_datos_asegurado_v",
            no_cotizacion: noCotizacion.cotizacion_general,
            userID: localStorage.getItem("agenteMail"),
            correo: emissionData?.emission?.contractorData?.email,
            telefono: emissionData?.emission?.contractorData?.phone,
          });
        }
      },
    },

    cp: {
      get() {
        return this.$store.state.emission.contractorData.cp;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "cp",
          value,
        });
      },
    },

    suburbCode: {
      get() {
        return this.$store.state.emission.contractorData.suburbCode;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "suburbCode",
          value,
        });
      },
    },

    suburbName: {
      get() {
        return this.$store.state.emission.contractorData.suburbName;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "suburbName",
          value,
        });
      },
    },

    stateName: {
      get() {
        return this.$store.state.emission.contractorData.stateName;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "stateName",
          value,
        });
      },
    },

    cityName: {
      get() {
        return this.$store.state.emission.contractorData.cityName;
      },
      set(value) {
        this.$store.dispatch("updateContractorDataProperty", {
          key: "cityName",
          value,
        });
      },
    },

    invoice: {
      get() {
        return this.$store.state.emission.emissionRequest.invoice;
      },
      set(value) {
        this.$store.dispatch("updateEmissionRequestProperty", {
          key: "invoice",
          value,
        });
      },
    },

    cfdi: {
      get() {
        return this.$store.state.emission.emissionRequest.cfdi;
      },
      set(value) {
        this.$store.dispatch("updateEmissionRequestProperty", {
          key: "cfdi",
          value,
        });
      },
    },

    use_cfdi: {
      get() {
        return this.$store.state.emission.emissionRequest.use_cfdi;
      },
      set(value) {
        this.$store.dispatch("updateEmissionRequestProperty", {
          key: "use_cfdi",
          value,
        });
      },
    },

    insurerName() {
      return this.$store.state.emission.insurerData.insurer;
    },
  },

  watch: {
    cp(v) {
      if (v && v.length == 5) this.getColonias();
    },

    suburbCode(v) {
      !!v && this.coloniaUpdated();
    },

    active(v) {
      if (v) {
        this.currentPanel = 0;
        this.isFormValidContractorData = false;
      } else this.currentPanel = 1;
    },
  },

  methods: {
    async getColonias() {
      console.log("Get colonia");
      const locations = await getInsurerLocations({
        insurer: this.insurerName,
        cp: this.cp,
      });
      this.colonias = locations;

      this.suburbCode = this.colonias[0].suburb_code;
    },

    coloniaUpdated() {
      const coloniaFound = this.colonias.find(
        (colonia) => colonia.suburb_code == this.suburbCode
      );
      this.stateName = coloniaFound.state_name;
      this.cityName = coloniaFound.city_name;
      this.suburbName = coloniaFound.suburb_name;
    },

    async nextStep() {
      await this.$refs.formData.validate();
      if (!this.isFormValidContractorData) return;
      const noCotizacion = JSON.parse(
        sessionStorage.getItem("cotizacionSelected")
      );
      const emissionData = JSON.parse(sessionStorage.getItem("contractorData"));
      window.dataLayer.push({
        event: "ITFY_auto_emitir_datos_contratante",
        no_cotizacion: noCotizacion.cotizacion_general,
        sexo: this.gender == "M" ? "Masculino" : "Femenino",
        cp: this.cp,
        correo: emissionData?.emission?.contractorData?.email,
        telefono: emissionData?.emission?.contractorData?.phone,
        userID: localStorage.getItem("agenteMail"),
        boton_contacto: "Listo",
      });
      window.dataLayer.push({
        event: "ITFY_auto_emitir_datos_asegurado_v",
        no_cotizacion: noCotizacion.cotizacion_general,
        userID: localStorage.getItem("agenteMail"),
        correo: emissionData?.emission?.contractorData?.email,
        telefono: emissionData?.emission?.contractorData?.phone,
      });
      this.$emit("nextStep");
    },

    async getInvoiceData() {
      this.cfdis = await getCfdis();
      this.usesCfdi = await getUsesCfdi();
    },
  },

  mounted() {
    this.isFormValidContractorData = false;
    this.getInvoiceData();
    const noCotizacion = JSON.parse(
      sessionStorage.getItem("cotizacionSelected")
    );
    const emissionData = JSON.parse(sessionStorage.getItem("contractorData"));
    if (this.active) {
      window.dataLayer.push({
        event: "ITFY_auto_emitir_datos_contratante_v",
        no_cotizacion: noCotizacion.cotizacion_general,
        userID: localStorage.agenteMail,
        correo: emissionData?.emission?.contractorData?.email,
        telefono: emissionData?.emission?.contractorData?.phone,
      });
    }
  },
};
</script>