import { mainAxios } from "@/mainAxios.js";

const getAgenteAccessToken = () => localStorage.getItem("agenteAccessToken");
const getInterToken = () => {
  const token = JSON.parse(sessionStorage.getItem("carPrice")).token;
  if (token) return token;
  throw new Error("No existe ningún token cargado de Inter");
};

export const getInsurerLocations = async ({ insurer, cp }) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
      "inter-token": getInterToken(),
    },
  };

  const uri = `/v1/cotizacion-ws/auto/${insurer}/location/${cp}`;
  const serverResponse = await mainAxios.get(uri, config);

  return serverResponse.data.data.locations;
};
