<template>
  <div>
    <common-expansion-panel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        <span class="titulo-header">Confirma tu Compra</span>
      </template>

      <v-expansion-panel-content>
        <span class="text-body-1 px-1"
          >¡Ya casi terminamos! Verifica tus datos</span
        >

        <v-row class="mt-5">
          <v-col cols="12">
            <span class="subtitulo-header">Contratante</span>
          </v-col>
          <v-col cols="12">
            <v-row
              v-for="(contractorDataItem, index) in contractorDataMap"
              :key="index"
              no-gutters
              class="text-body-1 mt-2"
              style="color: #4D5358;"
            >
              <v-col cols="12" md="6"> {{ contractorDataItem.text }}: </v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex font-weight-bold"
                :class="{ 'justify-end': $vuetify.breakpoint.mdAndUp }"
              >
                {{ contractorDataItem.value }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col cols="12">
            <span class="subtitulo-header">Vehículo</span>
          </v-col>
          <v-col cols="12">
            <v-row
              v-for="(vehiculoDataItem, index) in vehiculoDataMap"
              :key="index"
              no-gutters
              class="text-body-1 mt-2"
              style="color: #4D5358;"
            >
              <v-col cols="12" md="6"> {{ vehiculoDataItem.text }}: </v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex font-weight-bold"
                :class="{ 'justify-end': $vuetify.breakpoint.mdAndUp }"
              >
                {{ vehiculoDataItem.value }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-btn block rounded dark outlined color="#00a7e4">
              <span style="padding-right: 11%;">
                Regresar
              </span>
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn block rounded dark class="common-botton" @click="nextStep()">
              <span style="padding-right: 11%;">
                Pagar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </common-expansion-panel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";

export default {
  components: {
    CommonExpansionPanel,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPanel: 1,
    };
  },

  computed: {
    emissionData() {
      return this.$store.state.emission;
    },

    contractorDataMap() {
      if (!this.emissionData) return [];
      return [
        {
          text: "Nombre",
          value: `${this.emissionData.contractorData.firstName} ${this.emissionData.contractorData.lastname} ${this.emissionData.contractorData.secondLastname}`,
        },
        {
          text: "Sexo de Nacimiento",
          value:
            this.emissionData.contractorData.gender == "M"
              ? "Masculino"
              : "Femenino",
        },
        {
          text: "Edad",
          value: this.getAge(this.emissionData.contractorData.birthDate),
        },
        {
          text: "RFC",
          value: this.emissionData.emissionRequest.contractor_rfc,
        },
        {
          text: "Correo Electrónico",
          value: this.emissionData.contractorData.email,
        },
        {
          text: "Teléfono",
          value: this.emissionData.contractorData.phone,
        },
        {
          text: "Domicilio Fiscal",
          value: `${this.emissionData.emissionRequest.contact_street} Ext. ${this.emissionData.emissionRequest.contractor_ext}, Int. ${this.emissionData.emissionRequest.contractor_int}, ${this.emissionData.contractorData.suburbName}, ${this.emissionData.contractorData.cityName}, ${this.emissionData.contractorData.stateName}`,
        },
      ];
    },

    vehiculoDataMap() {
      if (!this.emissionData) return [];
      return [
        {
          text: "Marca/Modelo",
          value: `${this.emissionData.carData.brand} ${this.emissionData.carData.description}`,
        },
        {
          text: "Año",
          value: this.emissionData.carData.model,
        },
        {
          text: "Número de Serie (NIV)",
          value: this.emissionData.emissionRequest.vehicle_vin,
        },
        {
          text: "Número de placas (opcional)",
          value: this.emissionData.emissionRequest.vehicle_plate,
        },
      ];
    },
  },

  watch: {
    active(v) {
      if (v) this.currentPanel = 0;
      else this.currentPanel = 1;
    },
  },

  methods: {
    getAge(dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    nextStep() {
      this.$emit("nextStep");
      const noCotizacion = JSON.parse(sessionStorage.getItem('cotizacionSelected'));
      const emissionData = JSON.parse(sessionStorage.getItem('contractorData'))
      window.dataLayer.push({
        event: "ITFY_auto_emitir_confirmar_compra",
        no_cotizacion: noCotizacion.cotizacion_general,
        modalidad_pago: noCotizacion.tipoPago,
        plan: noCotizacion.plan,
        // colaborador: '',
        // codigo_promocion: '',
        aseguradora: noCotizacion.insurer,
        total_pago: noCotizacion.price,
        boton_contacto: 'Pagar',
        userID: localStorage.getItem("agenteMail"),
      })
      window.dataLayer.push({
        event: "ITFY_auto_emitir_datos_bancarios_v",
        no_cotizacion: noCotizacion.cotizacion_general,
        userID: localStorage.getItem('agenteMail'),
        correo: emissionData?.emission?.contractorData?.email,
        telefono: emissionData?.emission?.contractorData?.phone,
      })
    },
  },
};
</script>
