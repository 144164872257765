/**
 * Esta promesa se creó con la finalidad de ser reemplazada
 * por una petición Axios en caso de que el catálogo pase a estar
 * en el lado del backend. Así solo modificamos su uso por la petición
 * sin modificar los métodos donde es implementado este catálogo
 * - Alejandro Milán
 */
const cfdiUsesPromise = () => {
  return new Promise((resolve) => {
    resolve([
      {
        use: "G01",
        label: "Adquisición de mercancías",
      },
      {
        use: "G02",
        label: "Devoluciones, descuentos o bonificaciones",
      },
      {
        use: "G03",
        label: "Gastos en general",
      },
      {
        use: "I01",
        label: "Construcciones",
      },
      {
        use: "I02",
        label: "Mobiliario y equipo de oficina por inversiones",
      },
      {
        use: "I03",
        label: "Equipo de transporte",
      },
      {
        use: "I04",
        label: "Equipo de cómputo y accesorios",
      },
      {
        use: "I05",
        label: "Dados, troqueles, moldes, matrices y herramental",
      },
      {
        use: "I06",
        label: "Comunicaciones teléfonicas",
      },
      {
        use: "I07",
        label: "Comunicaciones satelitales",
      },
      {
        use: "I08",
        label: "Otra maquinaria y equipo",
      },
      {
        use: "D01",
        label: "Honorarios médicos, dentales y gastos hospitalarios",
      },
      {
        use: "D02",
        label: "Gastos médicos por incapacidad o discapacidad",
      },
      {
        use: "D03",
        label: "Gastos funerales",
      },
      {
        use: "D04",
        label: "Donativos",
      },
      {
        use: "D05",
        label:
          "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)",
      },
      {
        use: "D06",
        label: "Aportaciones voluntarias al SAR",
      },
      {
        use: "D07",
        label: "Primas por seguros de gastos médicos",
      },
      {
        use: "D08",
        label: "Gastos de transportación escolar obligatoria",
      },
      {
        use: "D09",
        label:
          "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones",
      },
      {
        use: "D10",
        label: "Pagos por servicioes educativos (colegiaturas)",
      },
      {
        use: "S01",
        label: "Sin efectos fiscales",
      },
      {
        use: "CP01",
        label: "Pagos",
      },
      {
        use: "CN01",
        label: "Nómina",
      },
    ]);
  });
};

export const getUsesCfdi = async () => {
  return await cfdiUsesPromise();
};

/**
 * Esta promesa se creó con la finalidad de ser reemplazada
 * por una petición Axios en caso de que el catálogo pase a estar
 * en el lado del backend. Así solo modificamos su uso por la petición
 * sin modificar los métodos donde es implementado este catálogo
 * - Alejandro Milán
 */
const cfdisPromise = () => {
  return new Promise((resolve) => {
    resolve([
      {
        cfdi: "605",
        label: "Sueldos y Salarios e Ingresos Asimilados a Salarios",
      },
      {
        cfdi: "606",
        label: "Arrendamiento",
      },
      {
        cfdi: "607",
        label: "Régimen de Enajenación o Adquisición de Bienes",
      },
      {
        cfdi: "608",
        label: "Demás Ingresos",
      },
      {
        cfdi: "610",
        label:
          "Residentes en el Extranjero sin Establecimiento Permanente en México",
      },
      {
        cfdi: "611",
        label: "Ingresos por Dividendos (socios y accionistas)",
      },
      {
        cfdi: "612",
        label: "Personas Físicas con Actividades Empresariales y Profesionales",
      },
      {
        cfdi: "614",
        label: "Ingresos por Intereses",
      },
      {
        cfdi: "615",
        label: "Régimen de los ingresos por obtención de premios",
      },
      {
        cfdi: "616",
        label: "Sin obligaciones fiscales",
      },
      {
        cfdi: "621",
        label: "Incorporación Fiscal",
      },
      {
        cfdi: "625",
        label:
          "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
      },
      {
        cfdi: "626",
        label: "Régimen Simplificado de Confianza",
      },
    ]);
  });
};

export const getCfdis = async () => {
  return await cfdisPromise();
};
