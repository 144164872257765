<template>
  <div>
    <v-row>
      <v-col cols="12">
        <common-card>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <span class="subtitulo-header-2 font-montserrat"
                  >Cotización</span
                >
              </v-col>
              <v-col cols="6">
                <span class="subtitulo-header-2 font-montserrat"
                  >#{{ cotizacionSelected.cotizacion_general }}</span
                >
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col>

      <v-col cols="12">
        <v-responsive
          class="justify-center align-center"
          style="text-align: center;"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="161"
            height="194"
            viewBox="0 0 161 194"
            fill="none"
          >
            <path
              d="M85.2668 14.7617C85.2668 14.7617 50.9334 29.9644 15.0391 29.9644C15.0391 29.9644 15.0391 71.7247 15.0391 113.862C15.0391 155.999 85.2668 188.773 85.2668 188.773C85.2668 188.773 155.494 155.999 155.494 113.862C155.494 71.7247 155.494 29.9644 155.494 29.9644C119.6 29.9644 85.2668 14.7617 85.2668 14.7617Z"
              fill="#00162E"
            />
            <path
              d="M85.2661 193.776C84.5435 193.776 83.8208 193.62 83.1501 193.307C80.1644 191.914 10.0332 158.671 10.0332 113.863V29.9638C10.0332 27.1993 12.2739 24.9586 15.0384 24.9586C49.4014 24.9586 82.9082 10.3316 83.2421 10.1832C84.5316 9.6134 86.0036 9.6134 87.2932 10.1832C87.7369 10.3791 121.189 24.9571 155.495 24.9571C158.26 24.9571 160.501 27.1978 160.501 29.9623V113.86C160.501 158.67 90.3693 191.912 87.3837 193.304C86.7115 193.62 85.9888 193.776 85.2661 193.776ZM20.0422 34.877V113.861C20.0422 148.398 73.8266 177.416 85.2661 183.193C96.7041 177.414 150.49 148.396 150.49 113.861V34.877C120.432 33.7893 92.8816 23.3233 85.2661 20.1952C77.6507 23.3233 50.1003 33.7893 20.0422 34.877Z"
              fill="#00162E"
            />
            <path
              d="M75.7335 5.22852C75.7335 5.22852 41.4002 20.4312 5.50586 20.4312C5.50586 20.4312 5.50586 62.1915 5.50586 104.329C5.50586 146.466 75.7335 179.24 75.7335 179.24C75.7335 179.24 145.961 146.466 145.961 104.329C145.961 62.1915 145.961 20.4312 145.961 20.4312C110.068 20.4312 75.7335 5.22852 75.7335 5.22852Z"
              fill="#007EBB"
            />
            <path
              d="M75.7329 184.243C75.0103 184.243 74.2876 184.087 73.6169 183.774C70.6312 182.38 0.5 149.138 0.5 104.33V20.4306C0.5 17.6661 2.74071 15.4253 5.50524 15.4253C39.8682 15.4253 73.375 0.798415 73.7089 0.650023C74.9984 0.0802006 76.4704 0.0802006 77.76 0.650023C78.2037 0.8459 111.656 15.4239 145.962 15.4239C148.727 15.4239 150.967 17.6646 150.967 20.4291V104.327C150.967 149.136 80.8361 182.379 77.8505 183.771C77.1783 184.087 76.4556 184.243 75.7329 184.243ZM10.509 25.3438V104.328C10.509 138.865 64.2934 167.883 75.7329 173.66C87.1709 167.881 140.957 138.863 140.957 104.328V25.3438C110.899 24.2561 83.3484 13.7901 75.7329 10.662C68.1175 13.7901 40.5671 24.2561 10.509 25.3438Z"
              fill="#00162E"
            />
            <path
              d="M77.9688 81.0352V90.8735H95.6199C95.6199 90.8735 92.1579 81.0352 77.9688 81.0352Z"
              fill="white"
            />
            <path
              d="M55.8496 90.8735H73.5008V81.0352C59.3101 81.0352 55.8496 90.8735 55.8496 90.8735Z"
              fill="white"
            />
            <path
              d="M100.989 120.095C104.659 119.891 107.469 116.751 107.266 113.081C107.063 109.411 103.923 106.601 100.253 106.804C96.5827 107.007 93.7722 110.147 93.9755 113.817C94.1787 117.487 97.3187 120.298 100.989 120.095Z"
              fill="white"
            />
            <path
              d="M95.4826 95.0703C89.3066 95.0703 82.4613 95.0703 75.7347 95.0703C69.0081 95.0703 62.1628 95.0703 55.9868 95.0703C34.8633 95.0703 31.1016 107.657 31.1016 107.657V115.47H37.7109C37.61 114.808 37.5403 114.134 37.5403 113.444C37.5403 106.093 43.4997 100.133 50.851 100.133C58.2023 100.133 64.1617 106.093 64.1617 113.444C64.1617 114.134 64.0934 114.808 63.991 115.47H75.7347H87.4784C87.3775 114.808 87.3077 114.134 87.3077 113.444C87.3077 106.093 93.2671 100.133 100.618 100.133C107.97 100.133 113.929 106.093 113.929 113.444C113.929 114.134 113.859 114.808 113.759 115.47H120.368V107.657C120.366 107.658 116.606 95.0703 95.4826 95.0703Z"
              fill="white"
            />
            <path
              d="M57.4915 113.916C57.7521 110.25 54.9911 107.066 51.3246 106.806C47.6582 106.545 44.4747 109.306 44.2141 112.973C43.9534 116.639 46.7144 119.823 50.3809 120.083C54.0474 120.344 57.2309 117.583 57.4915 113.916Z"
              fill="white"
            />
            <path
              d="M62.8596 74.0289C64.4206 72.2022 66.4269 70.8756 68.3886 69.9229C73.011 67.6763 78.454 67.6763 83.0779 69.9229C85.0396 70.8756 87.0444 72.2008 88.6069 74.0289C88.6069 74.0289 90.8328 55.3494 75.737 42.1841V42.1797C75.737 42.1812 75.7355 42.1812 75.734 42.1827C75.7325 42.1812 75.7325 42.1812 75.731 42.1797V42.1841C60.6337 55.3509 62.8596 74.0289 62.8596 74.0289Z"
              fill="white"
            />
            <path
              d="M56.7963 74.0294C56.0217 58.2495 67.0947 43.2441 67.0947 43.2441C40.1216 48.2791 33.8535 72.5781 33.8535 72.5781C47.2117 60.5733 56.7963 74.0294 56.7963 74.0294Z"
              fill="white"
            />
            <path
              d="M94.6719 74.0289C94.6719 74.0289 104.256 60.5728 117.616 72.5762C117.616 72.5762 111.348 48.2771 84.375 43.2422C84.3735 43.2437 95.4465 58.249 94.6719 74.0289Z"
              fill="white"
            />
          </svg>
        </v-responsive>
      </v-col>

      <v-col cols="12">
        <common-card>
          <v-card-text class="font-montserrat">
            <v-row>
              <v-col cols="6">
                <v-responsive
                  :aspect-ratio="1 / 1"
                  class="align-center"
                  width="50%"
                  height="100%"
                  style="margin: 0 auto;"
                >
                  <v-img :src="cotizacionSelected.logo" contain></v-img>
                </v-responsive>
              </v-col>
              <v-col cols="6" class="text-align-center">
                <v-responsive
                  :aspect-ratio="1 / 1"
                  class="align-center"
                  width="100%"
                  height="100%"
                  style="margin: 0 auto;"
                >
                  <p class="subtitulo-header-2 ma-0">
                    {{ currencyFormatt(cotizacionSelected.price) }}
                  </p>
                  <p style="text-align: center;">Costo anual</p>
                </v-responsive>
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col>

      <v-col cols="12">
        <common-card>
          <v-card-text>
            <div>
              <span class="subtitulo-header-2 font-montserrat">
                Detalle
              </span>
            </div>
            <v-row>
              <v-col cols="5">
                <span class="font-montserrat">
                  Total
                </span>
              </v-col>
              <v-col cols="7">
                <span class="font-montserrat font-weight-bold">
                  {{ currencyFormatt(cotizacionSelected.price) }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <span class="font-montserrat">
                  Modalidad de pago
                </span>
              </v-col>
              <v-col cols="7">
                <span class="font-montserrat font-weight-bold">
                  {{ modalidadPago }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <span class="font-montserrat">
                  Tipo de plan
                </span>
              </v-col>
              <v-col cols="7">
                <span class="font-montserrat font-weight-bold">
                  {{ tipoPlan }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col>

      <!-- <v-col cols="12">
        <common-card>
          <v-card-text>
            <div>
              <span class="subtitulo-header-2 font-montserrat">
                Cupones
              </span>
            </div>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Código de promoción"
                  outlined
                  background-color="rgba(255, 209, 0, 0.25)"
                >
                  <template #prepend>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 35 35"
                      fill="none"
                      style="margin-top: -10px;"
                    >
                      <path
                        d="M28.4375 5.46875C28.4375 4.59851 28.7832 3.76391 29.3986 3.14856C30.0139 2.5332 30.8485 2.1875 31.7188 2.1875C32.589 2.1875 33.4236 2.5332 34.0389 3.14856C34.6543 3.76391 35 4.59851 35 5.46875V29.5312C35 30.4015 34.6543 31.2361 34.0389 31.8514C33.4236 32.4668 32.589 32.8125 31.7188 32.8125C30.8485 32.8125 30.0139 32.4668 29.3986 31.8514C28.7832 31.2361 28.4375 30.4015 28.4375 29.5312V5.46875ZM26.25 7.0525C21.7284 9.13062 16.3209 10.2922 10.9375 10.675V24.3184C11.7321 24.3632 12.5256 24.4252 13.3175 24.5044C17.8084 24.9506 22.1506 25.9656 26.25 27.9169V7.0525ZM8.75 24.2047V10.7931C7.2625 10.8522 5.68969 10.8872 4.3575 10.9091C3.19887 10.9251 2.0929 11.3956 1.27786 12.2193C0.462809 13.0429 0.00390421 14.1538 0 15.3125L0 19.6875C0 22.1069 1.96 24.0537 4.36188 24.0822C4.72356 24.0867 5.08523 24.0925 5.44688 24.0997C6.54829 24.1217 7.64941 24.1567 8.75 24.2047ZM11.7906 26.5694C12.4141 26.6153 13.0353 26.6722 13.65 26.74L14.2034 30.4369C14.2637 30.7532 14.2533 31.079 14.1731 31.3909C14.0928 31.7028 13.9447 31.9931 13.7393 32.2412C13.5339 32.4892 13.2763 32.6888 12.9848 32.8258C12.6933 32.9627 12.3752 33.0336 12.0531 33.0334H10.8544C10.4292 33.0334 10.0132 32.9095 9.65733 32.6767C9.30146 32.444 9.02113 32.1126 8.85063 31.7231L5.97625 26.2981C7.21055 26.3289 8.4444 26.3756 9.6775 26.4381C10.3928 26.4753 11.0994 26.5191 11.7906 26.5694Z"
                        fill="#21272A"
                      />
                    </svg>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <a
                  class="text-decoration-underline font-weight-bold font-montserrat"
                  >¿Eres colaborador de interprotección?</a
                >
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";

export default {
  components: {
    CommonCard,
  },

  data() {
    return {
      cotizacionSelected: JSON.parse(
        sessionStorage.getItem("cotizacionSelected")
      ),
      carPrice: JSON.parse(sessionStorage.getItem("carPrice")),
      tiposPlan: [
        {
          id: "AMP",
          nombre: "AMPLIA",
        },
        {
          id: "RC",
          nombre: "RESPONSABILIDAD CIVIL",
        },
        {
          id: "LIMIT",
          nombre: "LIMITADA",
        },
      ],
      tiposPago: JSON.parse(sessionStorage.getItem("datosPago")),
    };
  },

  computed: {
    modalidadPago() {
      return this.tiposPago.find(
        (tipoPago) => tipoPago.inter == this.cotizacionSelected.tipoPago
      ).nombre;
    },

    tipoPlan() {
      return this.tiposPlan.find(
        (tipoPlan) => tipoPlan.id == this.cotizacionSelected.plan
      ).nombre;
    },
  },

  methods: {
    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      return formatoMonedaMXN.format(val);
    },
  },
};
</script>
