<template>
  <v-dialog v-model="dialog" persistent width="500">
    <common-card>
      <v-card-text class="pa-4 black--text" style="text-align: center;">
        <div
          class="pt-5 pb-5 top-text"
          style="width: 100%; height: 100%; justify-content: center;"
        >
          Ayuda con el pago
        </div>

        <div v-if="step == 1">
          <div class="pt-5 pb-5">
            <span class="confirmation-text">
              Lamentamos escuchar que tienes problemas con el pago. Nos podrias
              contar un poco mas de que fue lo que paso
            </span>
          </div>
          <div>
            <v-row style="align-items: center;">
              <v-col cols="12" style="text-align: center;">
                <v-radio-group v-model="OptionSelected" hide-details>
                  <v-radio
                    label="El pago me marca un error en mis datos personales."
                    value="1"
                  ></v-radio>
                  <v-radio
                    label="Necesito mas tiempo para realizar mi pago."
                    value="2"
                  ></v-radio>
                  <v-radio
                    label="El pago me arroja un error extraño."
                    value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
          <div style="padding-top: 10%;">
            <v-row>
              <v-col cols="6">
                <v-btn
                  text
                  color="#00a7e4"
                  outlined
                  dark
                  style="border-radius: 15px;"
                  @click="$emit('closeHelpModal')"
                >
                  Cancelar</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  class="common-botton"
                  dark
                  :disabled="OptionSelected == null"
                  @click="step = 2"
                  >Siguiente</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="step == 2">
          <div v-if="OptionSelected == 1 || OptionSelected == 3 && !decisionfinal">
            <div class="pt-5 pb-5">
              <span class="confirmation-text">
                Si tienes problemas con tus datos personales esto es lo que
                podemos hacer para ayudarte:
              </span>
            </div>
            <v-row style="align-items: center;">
              <v-col cols="12" style="text-align: center;">
                <v-radio-group v-model="personalOption" hide-details>
                  <v-radio
                    label="Crear una solicitud para que uno de nuestros ejecutivos de operaciones te ayude a pagar tu emision."
                    value="1"
                  ></v-radio>
                  <v-radio
                    label="Vuelve a darle un vistazo a tus datos."
                    value="2"
                  ></v-radio>
                </v-radio-group>
                <div class="pt-5">
                  <span class="confirmation-text">
                    O si lo prefieres puedes comunicarte por telefono al numero
                    : <strong>+52 (55) 8877 0545</strong>
                  </span>
                </div>
              </v-col>
            </v-row>
            <div style="padding-top: 10%;">
              <v-row>
                <v-col cols="6">
                  <v-btn
                    text
                    color="#00a7e4"
                    outlined
                    dark
                    style="border-radius: 15px;"
                    @click="backStep(1)"
                  >
                    Cancelar</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    class="common-botton"
                    dark
                    :disabled="personalOption == null"
                    @click="validateStep2PersonalData"
                    >Aceptar</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="OptionSelected == 2" >
            <div class="pt-5 pb-5">
              <span class="confirmation-text">
                Recargaremos tu pagina para que obtengas mas minutos de pago en
                <strong>{{ tiempo }}</strong> segundos
              </span>
            </div>
            <v-row style="text-align: center;">
              <v-col cols="12">
                <v-btn
                  text
                  color="#00a7e4"
                  outlined
                  dark
                  style="border-radius: 15px;"
                  :disabled="tiempo < 1"
                  @click="cancelarCuenta"
                >
                  Cancelar</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div v-if="personalOption == 1 && decisionfinal && solicitudCreada" >
            <span class="confirmation-text">
              ! Solicitud creada con éxito te redirigiremos a la vista de la solicitud y en breve un miembro de nuestro equipo de operaciones se pondra en contacto contigo¡
              </span>
          </div>
        </div>
      </v-card-text>
    </common-card>
  </v-dialog>
</template>

<script>
import CommonCard from "./CommonCard.vue";
import moment from "moment";
import { mainAxios } from '@/mainAxios.js';

export default {
  components: {
    CommonCard,
  },
  props: {
    dialog: Boolean,
  },

  data() {
    return {
      OptionSelected: null,
      step: 1,
      intervalo: null,
      tiempo: 10,
      personalOption: null,
      solicitudCreada: false,
      decisionfinal:false
    };
  },
  methods: {
    userChoice(val) {
      this.$emit("userChoice", val);
    },
    crearIntervalo() {
      this.intervalo = setInterval(() => {
        if (this.tiempo > 0) {
          this.tiempo--;
        } else {
          clearInterval(this.intervalo);
          location.reload();
        }
      }, 1000);
    },
    cancelarCuenta() {
      clearInterval(this.intervalo);
      this.tiempo = 10;
      this.step = 1;
      this.OptionSelected == null;
    },
    backStep(val) {
      (this.OptionSelected = null), (this.step = val), (this.intervalo = null);
      this.personalOption = null;
    },
    createSolicitud() {
      this.decisionfinal = true
      var cotizacionSelected = JSON.parse(
        sessionStorage.getItem("cotizacionSelected")
      )
      var cotizaciongeneral = cotizacionSelected.cotizacion_general
      var carPrice = JSON.parse(
        sessionStorage.getItem("carPrice")
      )
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      var request = {
        origenSolicitudSelected: null,
        agente: 908,
        categoriaSelected: "AUTOS",
        comercial_asignado: null,
        tipoSelected: "Otro",
        actividadSolicitud: null,
        producto_id: 923,
        detalle:
          "Se requiere apoyo del equipo de soporte para el pago de la emision con los siguientes datos:" + 
          "\nID:"+this.$store.state.emission.paymentData.polizaId +
          "\nCotizacion general: "+cotizaciongeneral +
          "\nFecha de emision: "+ moment().format('DD/MM/YYYY') + 
          "\nVIN del coche: " + this.$store.state.emission.emissionRequest.vehicle_vin +
          "\nTracking Id de Inter: "+ carPrice.trackingId,
        archivos: null,
        campania_id: 59,
        idOrigenLead: 1,
        inicio: moment().format('YYYY-MM-DD'),
        fechaPrimerPago: moment().add(1,'y').format('YYYY-MM-DD'),
        fin: moment().format('YYYY-MM-DD'),
        aseguradora_id: 27,
        campania: "Portal Asesores Digitales Autos",
        regimen_persona: "Física",
        razon_social: null,
        rfc: this.$store.state.emission.contractorData.rfc,
        operadorId: localStorage.id,
        nombre: null,
        fecha_nacimiento: null,
        edad: 24,
        correoElectronico: this.$store.state.emission.contractorData.email,
        nombres: this.$store.state.emission.contractorData.firstName,
        apellidoPaterno: this.$store.state.emission.contractorData.lastname,
        apellidoMaterno: this.$store.state.emission.contractorData.secondLastname,
        fechaNacimiento: this.$store.state.emission.contractorData.birthDate,
        genero: this.$store.state.emission.contractorData.gender,
        estadoCivilSelected: "Soltero(a)",
        celular: this.$store.state.emission.contractorData.phone,
        codigoPostal: this.$store.state.emission.contractorData.cp,
        calle: this.$store.state.emission.contractorData.secondLastname,
        estadoRepublicaSelected: this.$store.state.emission.contractorData.stateName,
        municipio: this.$store.state.emission.contractorData.cityName,
        numExterior: this.$store.state.emission.emissionRequest.contractor_ext,
        numInterior: this.$store.state.emission.emissionRequest.contractor_int,
        colonia: this.$store.state.emission.contractorData.suburbName,
        nombre_cliente: "",
        numeroPoliza: "Numero de poliza",
        polizaRenovar: null,
        fechaInicioVigenciaRenovar: null,
        fechaFinVigenciaRenovar: null,
        operadorUuid: null,
        procesoCotizacionSelected: "Cotización",
        solicitudCompletaSelected: false,
        checkPendienteInfo: false,
        checkCancelacion: false,
        causaPendienteInformacion: null,
        agenteId: cotizacionSelected.agenteId,
        productoId: 923,
        checkTramite:true,
        emision_id:this.$store.state.emission.paymentData.polizaId
      };
      mainAxios
        .post("v1/solicitud/insert/", request, config)
        .then((response) => {
          if (response.status == 200) {
            this.solicitudCreada = true
            setTimeout(()=>{
              this.$router.push('/detalles-solicitud/'+response.data.id)
            },5000)
          }
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(request);
    },
    validateStep2PersonalData() {
      switch (this.personalOption) {
        case "1":
          this.createSolicitud();
          break;
        case "2":
          this.$emit("toFirstStep");
          break;
        default:
          console.log("Sin opcion");
          break;
      }
    },
  },
  watch: {
    step(val) {
      if (val == 2 && this.OptionSelected == 2) {
        this.crearIntervalo();
      }
    },
    dialog(val) {
      if (!val) {
        (this.OptionSelected = null),
          (this.step = 1),
          (this.intervalo = null),
          (this.tiempo = 10),
          (this.personalOption = null);
      }
    },
  },
};
</script>

<style>
.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.top-text {
  width: 369px;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.confirmation-text {
  /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */

  width: 369px;
  height: 30px;

  /* Subtitle/S */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-align: center;

  color: #555555;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
</style>
