<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <datos-emision-vehiculo-form
          class="my-4"
          :active="currentStep == 1"
          @nextStep="toSecondStep"
          @directToPay="getPayment()"
          :useSameVinAndSameQuotation="useSameVinAndSameQuotation"
          :readonly="lastValidStep < 1"
        ></datos-emision-vehiculo-form>
        <datos-emision-contratante-form
          class="my-4"
          :active="currentStep == 2"
          @nextStep="currentStep = 3"
          :readonly="lastValidStep < 2"
        ></datos-emision-contratante-form>
        <datos-emision-asegurado-form
          class="my-4"
          :active="currentStep == 3"
          @nextStep="currentStep = 4"
          :readonly="lastValidStep < 3"
        ></datos-emision-asegurado-form>
        <confirmacion-datos-emision
          class="my-4"
          :active="currentStep == 4"
          :readonly="lastValidStep < 5"
          @nextStep="getPayment()"
        ></confirmacion-datos-emision>
        <pago-emision
          class="my-4"
          @ayudaAlPagar="toHelpCase"
          @openHelpModal="dialogHelp = true"
          :active="currentStep == 5"
          :readonly="lastValidStep < 6"
          :clearIntervaloPago="clearIntervaloPago"
        >
        </pago-emision>
      </v-col>

      <v-col cols="12" md="4">
        <div class="pt-5">
          <datos-emission-compra></datos-emission-compra>
        </div>
      </v-col>
    </v-row>

    <commond-loading
      :show="loading"
      :loadingImage="dataImage.iconCar"
      loadingText="Cargando datos para el pago..."
    />
    <CommonHelpModal
      :dialog="dialogHelp"
      @toFirstStep="toFirstStep"
      @closeHelpModal="dialogHelp = false"
    />
  </div>
</template>

<script>
import DatosEmisionVehiculoForm from "./DatosEmisionVehiculoForm.vue";
import DatosEmisionContratanteForm from "./DatosEmisionContratanteForm.vue";
import DatosEmisionAseguradoForm from "./DatosEmisionAseguradoForm.vue";
import ConfirmacionDatosEmision from "./ConfirmacionDatosEmision.vue";
import DatosEmissionCompra from "./DatosEmissionCompra.vue";
import PagoEmision from "./PagoEmision.vue";
import CommondLoading from "../../../../components/commonComponents/CommondLoading.vue";
import dataVehiculo from "@/helpers/data/data-loading-gif.json";
import CommonHelpModal from "@/components/commonComponents/CommonHelpModal.vue";
import { validarPagoEmision } from "@/services/emision.service";

export default {
  components: {
    DatosEmisionVehiculoForm,
    DatosEmisionContratanteForm,
    DatosEmisionAseguradoForm,
    ConfirmacionDatosEmision,
    DatosEmissionCompra,
    PagoEmision,
    CommondLoading,
    CommonHelpModal,
  },

  data() {
    return {
      currentStep: 1,
      lastValidStep: 0,
      dataImage: dataVehiculo,
      dialogHelp: false,
      clearIntervaloPago: false,
      useSameVinAndSameQuotation: false
    };
  },

  computed: {
    contractor() {
      return this.$store.state.emission.emissionRequest.contractor;
    },

    loading() {
      return this.$store.state.emission.loadingPaymentData;
    },
  },

  watch: {
    currentStep(v) {
      if (v > this.lastValidStep) this.lastValidStep = v;
    },

    contractor() {
      this.lastValidStep = 3;
      this.currentStep = 3;
    },
  },

  methods: {
    getPayment() {
      this.$store.dispatch("sendPaymentRequest");
      this.currentStep = 5;
      this.useSameVinAndSameQuotation = false
    },
    toHelpCase() {
      this.dialogHelp = true;
      this.clearIntervaloPago = false
    },
    toFirstStep(){
      this.currentStep = 1
      this.dialogHelp = false
      this.clearIntervaloPago = true
      this.useSameVinAndSameQuotation = true
    },
    toSecondStep(){
      this.currentStep = 2
      this.useSameVinAndSameQuotation = true
    }
  },
  async created(){
    var cotizacionSelected = JSON.parse(
        sessionStorage.getItem("cotizacionSelected")
      )

    var pagada = await validarPagoEmision({
        polizaId: cotizacionSelected.id,
    });
    console.log("pagada", pagada.data.data)

    if(cotizacionSelected.vin){
      this.toFirstStep()
    }
  }
};
</script>
